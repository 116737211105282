export default function IntroCardsInfo() {
  const cardsInfo = [
    {
      image: "card-1.webp",
      name: "الأكثر مبيعا",
      text: "مسك عبق الغيم الأكثر مبيعا في عالم العطور إغتنم الفرصة الحين ",
    },
    {
      image: "card-2.webp",
      name: "خصومات خيالية",
      text: "خصومات خيالية تصل الى %50",
    },
    {
      image: "card-3.webp",
      name: "تركيبة فريدة",
      text: "تركيبة فريدة مختلفة عن جميع أنواع المسك",
    },
  ];
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[30px]">
      {cardsInfo.map((card, index) => (
        <div key={index} className="p-[30px] bg-whiteColor shadow-customShadow rounded-[10px] text-center flex flex-col items-center justify-between gap-[20px]">
          <div className="w-full max-w-full sm:h-[450px] md:h-[350px] lg:h-[300px] xl:h-[400px] overflow-hidden rounded-[10px]">
            <img
              className="w-full h-full "
              src={`${process.env.PUBLIC_URL}/Unique Images/${card.image}`}
              alt={card.name}
            />
          </div>
          <div className=" text-whiteColor">
            <h2 className="text-mainColor font-bold text-[22px]">{card.name}</h2>
            <p className="text-[#666] my-[12px]">{card.text}</p>
            <a
              className=" inline-flex items-center gap-[15px]  transition-colors duration-500 border-b border-blackColor  pb-[5px] text-blackColor  font-semibold  hover:text-mainColor hover:border-b-mainColor"
              href="#shop"
            >
              <span> اطلب الحين</span>
              <i className="fa-solid fa-arrow-right animate-[moveRight_1s_ease-in-out_infinite]"></i>
            </a>
          </div>
        </div>
      ))}
    </div>
  );
}
