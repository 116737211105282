import { Autoplay, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "../StyleFiles/testimonials.css";
export default function Testimonials() {
  const testimonialsInfo = [
    {
      name: "ريم الحمدي",
      text: "يموتتتت والله العظيم ماندمت اني طلبته وباذن الله مو اول مره اطلبه خذوه وانتم مغمضينن👌🏻🤎",
    },
    {
      name: "هاجر الفايز",
      text: "اول ما تشمونه تحسونه ثقيل بعدين اذا حطيتوه على الجسم يصير بارد وفواح كل ماتحركتو تهف ريحته يجنن صراحة",
    },
    {
      name: "ظافر الشهري",
      text: "يعطيكم العافيه.. الرائحه جميله.. التغليف ٪؜١٠٠ واتمنى النظر بالاسعار وتنبيه شركات الشحن بعدم بقاء العطر في الشمس وشكرا",
    },
    {
      name: "منيره عبدالله",
      text: "المسك مره جميل وذوق وانصح فيه بقوه والتعامل مره جميل وما اكتفيت بطلب الاول طلبت مره ثانيه شكرا لكم",
    },
    {
      name: "ELHAM ATAALLAH",
      text: "للأمانه كنت متردده جداً إني أطلب ولكا وصلني الطلب أنصدمت أول شي من الكميه ومن المسك شي تحفه من الآخر 🥹💙💙💙",
    },
    {
      name: "Thekra",
      text: "رااائع وغريب صراحه حطيته ع يدي وثاابته الريحه ، ميزته في غرابه ريحته مرا حبيتها",
    },
    {
      name: "بشرى عسيري",
      text: "مره حلو تحسينه ينفع للكل نساء ورجال ريحته هاديه مره صرت استخدمه أنا وأخوي 👍🏻💯",
    },
    {
      name: "أم أديب",
      text: "المسك يجنننننن قسم بالله انه رهييب ريحة نظافه على مسك يستاااهل الكل يكون عنده😍",
    },
    {
      name: "غدي البلوي",
      text: "ماشاءالله المسك ريييحته تاخذ القلب وفواااح بس نقطه حطيت ماشاءالله الريحة تمشي قبلي بإذن الله حارجع اطلب ثاني ✨🤍",
    },
    {
      name: "امل البشري",
      text: "مثل ماتوقعت ريحته تجنن وهاااادية تنفع كثير للدوام وماخاب طني برجع اطلبه اكيد",
    },
  ];
  return (
    <div className="my-[130px]">
      <div className="text-center font-bold text-mainColor text-[22px] md:text-[30px] lg:text-[40px]">
        <h3>آراء العملاء</h3>
      </div>
      <div className="my-[80px]">
        <Swiper
          modules={[Pagination, Autoplay]}
          spaceBetween={50}
          grabCursor={true}
          pagination={{
            el: ".pagination",
            type: "bullets",
            clickable: true,
            bulletClass: "swiper-pagination-bullet",
            bulletActiveClass: "swiper-pagination-bullet-active",
            renderBullet: function (index, className) {
              return '<span class="' + className + '"></span>';
            },
          }}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          breakpoints={{
            375: {
              slidesPerView: 1,
            },
            576: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            992: {
              slidesPerView: 2,
            },
            1200: {
              slidesPerView: 3,
            },
            1400: {
              slidesPerView: 3,
            },
          }}
          className="swiper_container"
        >
          {testimonialsInfo.map((slider, index) => (
            <SwiperSlide key={index} className="swiperCard">
              <div>
                <p>{slider.text}</p>
                <h4>{slider.name}</h4>
              </div>
            </SwiperSlide>
          ))}
          <div className="pagination"></div>
        </Swiper>
      </div>
    </div>
  );
}
