import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./all.min.css";
import reportWebVitals from "./reportWebVitals";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import Replace from "./Components/Policy/Replace";
import Policy from "./Components/Policy/Policy";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./Pages/Home";
import Success from "./Components/Success";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <>
        <Home />
      </>
    ),
  },
  {
    path: "/policy",
    element: (
      <>
        <Header />
        <Policy />
        <Footer />
      </>
    ),
  },
  {
    path: "/replace",
    element: (
      <>
        <Header />
        <Replace />
        <Footer />
      </>
    ),
  },
  {
    path: "/success",
    element: (
      <>
        <Header />
        <Success />
        <Footer />
      </>
    ),
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
