import Footer from "../Components/Footer";
import Header from "../Components/Header";
import Landing from "../Components/Landing";

export default function Home(){
    return(
        <>
        <Header/>
        <Landing/>
        <Footer/>
        </>
    )
}