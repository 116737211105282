import axios from "axios";
import { useNavigate } from "react-router-dom";

import { useEffect, useRef, useState } from "react";
import "../StyleFiles/shop.css";
const PROXY_URL =
  "https://script.google.com/macros/s/AKfycbwavE5nFihq5aMTHKHplAoclIBIOVq4bWzpZetafewSVpK-2YTdL-8Zn6XatZISg-TJ/exec";
export default function Shop() {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("05");
  const [fristAddress, setFristAddress] = useState("");
  const [lastAddress, setLastAddress] = useState("");
  const [accept, setAccept] = useState(false);
  const [shopData, setShopData] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const formRef = useRef(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get("/shop.json");
        setShopData(response.data);
      } catch (error) {
        throw error;
      }
    }
    fetchData();
  }, []);

  const handleAddToCart = (product) => {
    setSelectedProduct(product.name);
    setTimeout(() => {
      if (formRef.current) {
        window.scrollTo({
          top: formRef.current.offsetTop,
          behavior: "smooth",
        });
      }
    }, 100);
  };

  async function submit(e) {
    e.preventDefault();
    setAccept(true);
    if (!name || !phone || !fristAddress || !lastAddress || !selectedProduct) {
      setError("يرجى ملء جميع الحقول المطلوبة");
      return;
    }

    if (!/^05\d{8}$/.test(phone)) {
      setError("الرجاء إدخال رقم هاتف صحيح");
      return;
    }

    setError("");
    const formElement = e.target;
    const formData = new FormData(formElement);
    try {
      setLoading(true);
      const response = await axios.post(PROXY_URL, formData);
      formElement.reset();
      setName("");
      setPhone("05");
      setFristAddress("");
      setLastAddress("");
      setSelectedProduct("");
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
        navigate("/success");
      }, 3000);
      setAccept(false);
      return response.data;
    } catch (error) {
      setLoading(false);
      throw error;
    }
  }
  const handlePhoneChange = (e) => {
    const value = e.target.value;
    if (/^05\d{0,8}$/.test(value)) {
      setPhone(value);
    }
  };

  return (
    <div>
      {success && <div className="popUp">تم إرسال البيانات بنجاح!</div>}
      {loading && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      )}
      <div
        id="shop"
        className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[30px] "
      >
        {shopData &&
          shopData.products.map((product) => (
            <div
              key={product.id}
              className="shop-card relative text-center rounded-[20px] overflow-hidden shadow-customShadow  flex flex-col items-center justify-between"
            >
              <div className="h-[300px]  sm:h-[420px] w-full  overflow-hidden bg-red-500">
                <img
                  className="w-full h-full object-cover"
                  src={product.image}
                  alt={product.name}
                />
              </div>
              <div className="my-[8px]">
                {product && product.shipping && (
                  <div className="absolute top-[20px] left-[50%] translate-x-[-50%] bg-[#f9b234] text-[#d52a00] rounded-[2px] font-semibold  w-full py-[6px]">
                    شحن مجاني!
                  </div>
                )}
                {product && product.shipping && product.best && (
                  <div className="absolute top-[20px] left-[50%] translate-x-[-50%] bg-[#f9b234] text-[#d52a00] rounded-[2px] font-semibold  w-full py-[6px]">
                    شحن مجاني!{" "}
                    <span className="mx-[6px] text-blackColor">+</span> أفضل
                    إختيار
                  </div>
                )}
                <h3 className="mb-[12px] text-[25px] font-bold text-blackColor">
                  {product.name}
                </h3>
              </div>
              <div>

                <p className="mb-[20px] text-mainColor font-semibold">
                  {product.price} ريال
                </p>
              </div>
              <button
                onClick={() => handleAddToCart(product)}
                disabled={selectedProduct === product.name}
                className="w-full h-[45px] text-whiteColor font-semibold bg-mainColor transition-colors duration-500 hover:bg-blackColor cursor-pointer"
              >
                أضف للسلة
              </button>
            </div>
          ))}
      </div>
      <div className="my-[160px]" ref={formRef}>
        <form
          onSubmit={(e) => submit(e)}
          className="form shadow-customShadow rounded-[20px]"
        >
          <div>
            <label htmlFor="name">إسمك</label>
            <input
              type="text"
              name="Name"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            {accept && name === "" && (
              <p className="error">من فضلك أدخل إسمك</p>
            )}
          </div>
          <div>
            <label htmlFor="phoneInput">رقم الجوال</label>
            <input
              id="phoneInput"
              type="tel"
              name="Phone"
              placeholder="05xxxxxxxxxx"
              value={phone}
              onChange={handlePhoneChange}
            />
            {accept && phone === "" && (
              <p className="error">من فضلك أدخل رقم الجوال</p>
            )}
          </div>
          <div>
            <label htmlFor="addressone">المدينة</label>
            <input
              id="addressone"
              type="text"
              name="MessageOne"
              value={fristAddress}
              onChange={(e) => setFristAddress(e.target.value)}
            />
            {accept && fristAddress === "" && (
              <p className="error">ما هي مدينتك ؟</p>
            )}
          </div>
          <div>
            <label htmlFor="addressTwo">الحي أو الشارع</label>
            <input
              id="addressTwo"
              type="text"
              name="MessageTwo"
              value={lastAddress}
              onChange={(e) => setLastAddress(e.target.value)}
            />
            {accept && lastAddress === "" && (
              <p className="error">ما إسم الحي أو الشارع التابع لك ؟</p>
            )}
          </div>
          <div>
            <div>
              <label htmlFor="product">المنتج</label>
              <select
                id="product"
                name="MessageThree"
                value={selectedProduct}
                onChange={(e) => setSelectedProduct(e.target.value)}
              >
                <option>اختر المنتج</option>
                {shopData &&
                  shopData.products.map((product) => (
                    <option
                      key={product.id}
                      value={product.name}
                      className="font-semibold"
                    >
                      {product.name}
                    </option>
                  ))}
              </select>
            </div>
            {accept && !selectedProduct && (
              <p className="error">من فضلك اختر المنتج</p>
            )}
          </div>
          <button type="submit"> إرسال... </button>
          {error && <p className="error">{error}</p>}
        </form>
      </div>
    </div>
  );
}
