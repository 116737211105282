import { Link } from "react-router-dom";

export default function Policy() {
    const PolicyInfo = [
      {
        name: "الخصوصية وبيان سرية المعلومات",
        text: "نقدر مخاوفكم واهتمامكم بشأن خصوصية بياناتكم على شبكة الإنترنت.  لقد تم إعداد هذه السياسة لمساعدتكم في تفهم طبيعة البيانات التي نقوم بتجميعها منكم عند زيارتكم لموقعنا أو تطبيقنا على شبكة الإنترنت وكيفية تعاملنا مع هذه البيانات الشخصية. ",
      },
      {
        name: "التصفح ",
        text: "لم نقم بتصميم هذا الموقع أو التطبيق من أجل تجميع بياناتك الشخصية من جهاز الكمبيوتر الخاص بك أثناء تصفحك لهذا الموقع أو التطبيق، وإنما سيتم فقط استخدام البيانات المقدمة من قبلك بمعرفتك ومحض إرادتك. ",
      },
      {
        name: "عنوان بروتوكول شبكة الإنترنت (IP) ",
        text: "في أي وقت تزور فيه أي موقع إنترنت بما فيها هذا الموقع أو التطبيق، سيقوم السيرفر المضيف بتسجيل عنوان بروتوكول شبكة الإنترنت (IP) الخاص بك، تاريخ ووقت الزيارة ونوع متصفح الإنترنت الذي تستخدمه والعنوان URL الخاص بأي موقع من مواقع الإنترنت التي تقوم بإحالتك إلى هذا الموقع على الشبكة. ",
      },
      {
        name: "عمليات المسح على الشبكة",
        text: "إن عمليات المسح التي نقوم بها مباشرة على الشبكة تمكننا من تجميع بيانات محددة مثل البيانات المطلوبة منك بخصوص نظرتك وشعورك تجاه موقعنا أو التطبيق الخاص بنا. تعتبر ردودك ذات أهمية قصوى، ومحل تقديرنا حيث أنها تمكننا من تحسين مستوى الموقع والتطبيق، ولك كامل الحرية والإختيار في تقديم البيانات المتعلقة بإسمك والبيانات الأخرى",
      },
      {
        name: "الروابط بالمواقع الأخرى على شبكة الإنترنت  ",
        text: "قد يشتمل موقعنا على روابط بالمواقع الأخرى على شبكة الإنترنت إعلانات من مواقع أخرى مثل Google AdSense ولا نعتبر مسئولين عن أساليب تجميع البيانات من قبل تلك المواقع, يمكنك الاطلاع على سياسات السرية والمحتويات الخاصة بتلك المواقع التي يتم الدخول إليها من خلال أي رابط ضمن هذا الموقع.  نحن قد نستعين بشركات إعلان لأطراف ثالثة لعرض الإعلانات عندما تزور موقعنا على الويب. يحق لهذه الشركات أن تستخدم معلومات حول زياراتك لهذا الموقع ولمواقع الويب الأخرى (باستثناء الاسم أو العنوان أو عنوان البريد الإلكتروني أو رقم الهاتف)، وذلك من أجل تقديم إعلانات حول البضائع والخدمات التي تهمك.",
      },
  
      {
        name: "إفشاء المعلومات ",
        text: "سنحافظ في كافة الأوقات على خصوصية وسرية كافة البيانات الشخصية التي نتحصل عليها. ولن يتم إفشاء هذه المعلومات إلا إذا كان ذلك مطلوباً بموجب أي قانون أو عندما نعتقد بحسن نية أن مثل هذا الإجراء سيكون مطلوباً أو مرغوباً فيه للتمشي مع القانون، أو للدفاع عن أو حماية حقوق الملكية الخاصة بهذا الموقع أو الجهات المستفيدة منه. ",
      },
      {
        name: "البيانات اللازمة لتنفيذ المعاملات المطلوبة من قبلك ",
        text: "عندما نحتاج إلى أية بيانات خاصة بك، فإننا سنطلب منك تقديمها بمحض إرادتك. حيث ستساعدنا هذه المعلومات في الاتصال بك وتنفيذ طلباتك حيثما كان ذلك ممكنناً. لن يتم إطلاقاً بيع البيانات المقدمة من قبلك إلى أي طرف ثالث بغرض تسويقها لمصلحته الخاصة دون الحصول على موافقتك المسبقة والمكتوبة ما لم يتم ذلك على أساس أنها ضمن بيانات جماعية تستخدم للأغراض الإحصائية والأبحاث دون اشتمالها على أية بيانات من الممكن استخدامها للتعريف بك. ",
      },
      {
        name: "عند الاتصال بنا ",
        text: "سيتم التعامل مع كافة البيانات المقدمة من قبلك على أساس أنها سرية . تتطلب النماذج التي يتم تقديمها مباشرة على الشبكة تقديم البيانات التي ستساعدنا في تحسين موقعنا.سيتم استخدام البيانات التي يتم تقديمها من قبلك في الرد على كافة استفساراتك، ملاحظاتك، أو طلباتك من قبل هذا الموقع أو أياً من المواقع التابعة له. ",
      },
      {
        name: "إفشاء المعلومات لأي طرف ثالث",
        text: "لن نقوم ببيع، المتاجرة، تأجير، أو إفشاء أية معلومات لمصلحة أي طرف ثالث خارج هذا الموقع أو التطبيق، أو المواقع التابعة له. وسيتم الكشف عن المعلومات فقط في حالة صدور أمر بذلك من قبل أي سلطة قضائية أو تنظيمية.  ",
      },
      {
        name: "التعديلات على سياسة سرية وخصوصية المعلومات ",
        text: "نحتفظ بالحق في تعديل بنود وشروط سياسة سرية وخصوصية المعلومات إن لزم الأمر ومتى كان ذلك ملائماً. سيتم تنفيذ التعديلات هنا وسيتم بصفة مستمرة إخطارك بالبيانات التي حصلنا عليها، وكيف سنستخدمها والجهة التي سنقوم بتزويدها بهذه البيانات.  ",
      },
      {
        name: "الاتصال بنا ",
        text: "يمكنكم الاتصال بنا عند الحاجة من خلال الضغط على رابط اتصل بنا المتوفر في روابط موقعنا أو تطبيقنا أو إرسال رسالة على بريدنا الإلكتروني Info@abaqstoresa.com",
      },
      {
        name: "اخيرًا",
        text: "إن مخاوفك واهتمامك بشأن سرية وخصوصية البيانات تعتبر مسألة في غاية الأهمية بالنسبة لنا. نحن نأمل أن يتم تحقيق ذلك من خلال هذه السياسة",
      },
    ];
    
    return (
      <section className="my-[160px] container">
        <div>
          <h2 className="text-center font-bold text-[35px] text-mainColor">
            سياسة الخصوصية
          </h2>
          <div className="my-[80px] flex flex-col gap-[35px]">
            {PolicyInfo.map((card, index) => (
              <div key={index}>
                <h4 className="mb-[10px] text-[18px] font-bold text-blackColor">
                  {card.name}
                </h4>
                <p className="text-[#666] leading-[1.6]">{card.text}</p>
              </div>
            ))}
          </div>
          <Link to={'/'} className="font-semibold text-[#666]">
          الذهاب للصفحة الرئيسة
          </Link>
        </div>
      </section>
    );
  }
  