import { Link } from "react-router-dom";

export default function Header() {
  return (
    <section className=" w-full z-[1000] ">
      <header className=" h-[60px]  lg:h-[80px] bg-whiteColor flex items-center justify-center  border-b-[2px] border-mainColor">
        <Link to={'/'} className="text-mainColor font-bold text-[22px] md:text-[25px] lg:text-[30px] font-customFont">
          مسك
        </Link>
      </header>
    </section>
  );
}
