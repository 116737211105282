import "../StyleFiles/landing.css";
import IntroCardsInfo from "./IntroCrdsInfo";
import MeskInfo from "./MeskInfo";
import Shop from "./Shop";
import Testimonials from "./Testimonials";
export default function Landing() {

  return (
    <section className="container my-[30px] ">
      <div className="flex flex-col-reverse  lg:flex-row items-center gap-[50px]">
        <div className="flex-1 text-center  lg:text-right">
          <h2 className="font-bold text-[25px] sm:text-[35px] lg:text-[40px] xl:text-[60px] text-mainColor leading-[1.4] ">
            المسك هو الفن الذي <br /> يجعل الذاكرة تتحدث
          </h2>
          <p className="my-[20px] sm:px-[100px] md:px-[0px] xl:pl-[220px] text-[16px] text-[#666]">
            يضع المسك اللمسات الأخيرة علي الأناقة فهو من التفاصيل التي تبرز
            المظهر بمهارة, كما أنه إضافة غير مرئية تكمل شخصية المرأة بدونها هناك
            شيء مفقود.
          </p>
          <a
            href="#shop"
            className="inline-flex gap-[12px] items-center px-[50px] py-[8px] font-semibold  border-[2px] border-mainColor rounded-[50px] text-blackColor transition-colors duration-500 hover:bg-mainColor hover:text-whiteColor"
          >
            <span>اطلب الحين</span>
            <i className="fa-solid fa-cart-plus"></i>
          </a>
        </div>
        <div className="bg-wrapper relative max-w-full w-[300px] h-[300px] sm:w-[350px] sm:h-[350px] md:w-[400px] md:h-[400px] lg:w-[450px] lg:h-[450px] xl:w-[500px] xl:h-[500px] bg-mainColor element-center rounded-t-[50%]"></div>
      </div>
      <div className="my-[160px]">
        <IntroCardsInfo />
      </div>
      <div className="my-[160px]">
        <MeskInfo />
      </div>
      <div className="my-[160px]">
        <Testimonials />
      </div>

      <div className="my-[160px]">
        <Shop />
      </div>
    </section>
  );
}
