import "../StyleFiles/meskInfo.css";
export default function MeskInfo() {
  const meskInfo = [
    {
      name: "مسك عبق الغيم",
      text: "أكثر ثباتاً وجمالاً مع البشرة الباردة",
      text2: "رائحة تخليك منتعشه طول اليوم",
      cards: [
        {
          icon: "perfume.png",
          text: "فواح بثبات عالي",
        },
        {
          icon: "smile.png",
          text: "تميز بحضور لافت وأكثر تألق",
        },
        {
          icon: "ok.png",
          text: "تركيبة فريدة غير كل أنواع المسك",
        },
        {
          icon: "capacity.png",
          text: "سعة العبوة 10 مل",
        },
      ],
    },
  ];
  const aboutInfo = [
    {
      name: "لماذا  نحن؟",
      text: "أجود أنواع المسك الفاخر",
      text2: "ثابت ويدوم",
      cards: [
        {
          icon: "free-delivery.png",
          text: "شحن مجاني",
        },
        {
          icon: "wallet.png",
          text: "دفع عند الإستلام",
        },
        {
          icon: "badge.png",
          text: "جودة ولا في الخيال!",
        },
        {
          icon: "satisfaction.png",
          text: "ضمان ذهبي",
        },
      ],
    },
  ];
  return (
    <div className="grid grid-cols-1 shadow-customShadow rounded-[12px] overflow-hidden">
      <div className="flex flex-col  lg:flex-row ">
        <div className="flex-1">
          {meskInfo.map((card, index) => (
            <div key={index} className="p-[20px]">
              <div>
                <h3 className="text-[22px] sm:text-[25px] md:text-[30px] xl:text-[60px] font-bold text-mainColor">
                  {card.name}
                </h3>
                <div className="my-[20px] flex flex-col gap-[12px] text-blackColor font-semibold text-[14px] sm:text-[18px] md:text-[18px] lg:text-[25px]">
                  <p>{card.text}</p>
                  <p>{card.text2}</p>
                </div>
              </div>
              <div className="grid grid-cols-2 xl:grid-cols-4 gap-[20px] my-[60px]">
                {card.cards.map((box, index) => (
                  <div
                    key={index}
                    className="rounded-[12px] bg-whiteColor py-[30px] px-[12px] shadow-customShadow text-center element-center flex-col gap-[12px]"
                  >
                    <img
                      className="w-[40px]"
                      src={`${process.env.PUBLIC_URL}/Unique Images/${box.icon}`}
                      alt={box.text}
                    />
                    <h4 className="font-semibold text-blackColor text-[14px] sm:text-[18px] md:text-[18px]">
                      {box.text}
                    </h4>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
        <div className="meskinfo lg:w-[50%] w-full bg-mainColor max-w-full  h-[300px] sm:h-[350px]  md:h-[500px] lg:h-full xl:h-full  element-center"></div>
      </div>
      <div className="flex flex-col-reverse  lg:flex-row">
        <div className="aboutInfo lg:w-[50%] w-full bg-mainColor max-w-full  h-[300px] sm:h-[350px]  md:h-[500px] lg:h-full xl:h-full  element-center"></div>
        <div className="flex-1">
          {aboutInfo.map((card, index) => (
            <div key={index} className="card p-[20px]">
              <div>
                <h3 className="text-[22px] sm:text-[25px] md:text-[30px] xl:text-[60px] font-bold text-mainColor">
                  {card.name}
                </h3>
                <div className="my-[20px] flex flex-col gap-[12px] text-blackColor font-semibold text-[14px] sm:text-[18px] md:text-[18px] lg:text-[25px]">
                  <p>{card.text}</p>
                  <p>{card.text2}</p>
                </div>
              </div>
              <div className="grid grid-cols-2 xl:grid-cols-4 gap-[20px] my-[60px]">
                {card.cards.map((box, index) => (
                  <div
                    key={index}
                    className="rounded-[12px] bg-whiteColor py-[30px] px-[12px] shadow-customShadow text-center element-center flex-col gap-[12px]"
                  >
                    <img
                      className="w-[40px]"
                      src={`${process.env.PUBLIC_URL}/Unique Images/${box.icon}`}
                      alt={box.text}
                    />
                    <h4 className="font-semibold text-blackColor text-[14px] sm:text-[18px] md:text-[18px]">
                      {box.text}
                    </h4>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
