import { NavLink } from "react-router-dom";
import "../StyleFiles/footer.css";
export default function Footer() {
  const policyLinks = [
    {
      linkName: "سياسة الخصوصية",
      to: "/policy",
    },
    {
      linkName: "سياسة الإستبدال و الإرجاع",
      to: "/replace",
    },
  ];
  const handleActiveLink = () => {};
  const year = new Date().getFullYear();
  return (
    <footer className="bg-[#eee] py-[30px] px-[20px] element-center flex-col text-center text-blackColor font-semibold text-[14px]">
      <p>
        جميع الحقوق محفوظة لمتجر عبق - <span>{year}</span>&copy;
      </p>
      <div className=" footer-links relative flex items-center justify-center gap-[20px] mt-[20px] text-[12px] ">
        {policyLinks.map((link, index) => (
          <NavLink
            key={index}
            to={link.to}
            onClick={handleActiveLink}
            className="relative hover:text-mainColor transition-colors duration-500 "
          >
            {link.linkName}
          </NavLink>
        ))}
      </div>
    </footer>
  );
}
