import { Link } from "react-router-dom";
import "../StyleFiles/success.css";
export default function Success() {
  return (
    <section className="container main my-[30px] flex items-center justify-center">
      <div className="shadow-customShadow  w-[350px]  h-[300px] lg:w-[450px] lg:h-[450px] max-w-full rounded-[10px] flex items-center justify-center text-center flex-col p-[10px]">
        <div className="w-[80px] h-[80px] lg:w-[120px] lg:h-[120px] element-center ">
          <img
            className="w-full h-full object-contain"
            src={require("../Images/thumb-up.png")}
            alt="img"
          />
        </div>
        <div className="my-[30px] md:my-[40px] lg:my-[50px]  font-semibold text-sm sm:text-[18px]">
          <p className="text-blackColor mb-[15px]">تم إستلام طلبك بنجاح </p>
          <p className="text-blackColor">سيتم التواصل معك في أقرب وقت ممكن</p>
        </div>
        <Link
          className="bg-mainColor text-whiteColor font font-semibold px-[30px] py-[10px] sm:px-[30px] sm:py-[10px] lg:px-[40px] lg:py-[12px] rounded-[50px] transition-colors duration-500 hover:bg-blackColor"
          to={"/"}
        >
          الرجوع للصفحة الرئيسية
        </Link>
      </div>
    </section>
  );
}
