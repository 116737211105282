import { Link } from "react-router-dom";

export default function Replace() {
  const replaceInfo = [
    {
      name: "أحكام وشروط الاستخدام والخدمة:",
      textGroup: [
        {
          text: "يجوز للمتجر أن يختار قبول أو عدم قبول أو إلغاء طلبيتك في بعض الحالات وعلى سبيل المثال ان كان المنتج الذي ترغب بشرائه غير متوفر أو في حال تم تسعير المنتج بطريقة خاطئة أو في حال تبين ان الطلبية احتيالية، سوف يقوم الموقع بإعادة ما قمت بدفعه للطلبيات التي لم يتم قبولها أو التي يتم إلغاؤها.",
        },
        {
          text: "جميع المحتويات على الموقع بما في ذلك الكتابات والتصاميم والرسومات والشعارات والأيقونات والصور والواجهات والرموز والبرامج والعلامة التجارية ملكية حصرية يملكها المتجر وتكون هذه الملكية خاضعة لحماية حقوق النشر.",
        },
        {
          text: "يحتفظ موقع لنفسه بالحق بأن يجري أية تعديلات أو تغييرات على موقعه وعلى السياسات والاتفاقيات المرتبطة به بما في ذلك سياسة الخصوصية وكذلك الوثيقة لأحكام، وشروط الخدمة، وشروط الاستبدال، والاسترجاع.",
        },
      ],
    },
    {
      name: "سياسة الاستبدال والاسترجاع:",
      textGroup: [
        {
          text: "يسمح بإرجاع جميع المنتجات خلال مدة لا تتجاوز 3 أيام من استلام الطلب، وحفاظاً على جودة المنتج وصلاحية الحفظ فإنه لا يمكن إرجاعها بعد ذلك.",
        },
        {
          text: "كما يسمح باستبدال المنتج خلال مدة لا تتجاوز 7 أيام من استلام الطلب.",
        },
        {
          text: "سيتم خصم جميع مبالغ الشحن والتوصيل، ويشمل ذلك تكلفة الشحن لتوصيل الطلب لك، بالإضافة إلى تكلفة الشحن لإرجاع الطلب إلى المتجر، حيث يتم خصم هذه التكلفة لصالح شركات الشحن.",
        },
      ],
    },
    {
      name: "الحالات التي لا يتم إعادة المنتجات بها:",
      textGroup: [
        {
          text: "عند تقديم طلب الإرجاع بعد الوقت المحدد، وهو 3 أيام من تاريخ الاستلام بخصوص المنتجات",
        },
        {
          text: "عند استخدام المنتج، أو عند تلفه، أو عندما لا يكون في نفس الحالة التي قمت باستلامها به.",
        },
        {
          text: "منتجات مستهلكه تم استخدامها.",
        },
        {
          text: "منتجات تم العبث بها أو إزالة أرقامها التسلسلية.",
        },
      ],
    },
  ];
  return (
    <section className="my-[160px] container">
      <div>
        <h2 className="text-center font-bold text-[35px] text-mainColor">
          سياسة الاستبدال والارجاع
        </h2>
        <div className="my-[80px] flex flex-col gap-[35px]">
          {replaceInfo.map((card, index) => (
            <div key={index}>
              <h4 className="mb-[10px] text-[18px] font-bold text-blackColor">
                {card.name}
              </h4>
              {card.textGroup.map((textinfo, index) => (
                <p key={index} className="text-[#666] mt-[8px] leading-[1.6]">
                  {textinfo.text}
                </p>
              ))}
            </div>
          ))}
        </div>
        <Link to={"/"} className="font-semibold text-[#666]">
          الذهاب للصفحة الرئيسة
        </Link>
      </div>
    </section>
  );
}
